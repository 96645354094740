import firebase from 'firebase';

const config = {
  apiKey: 'AIzaSyDGLSNKxBEWYnpc2uhx8uik_vlqPJybv6U',
  authDomain: 'mybook-belen.firebaseapp.com',
  projectId: 'mybook-belen',
  storageBucket: 'mybook-belen.appspot.com',
  messagingSenderId: '86308087583',
  appId: '1:86308087583:web:d2e2d714b0ccb7b058f22a',
  measurementId: 'G-Q4L7CCQC14',
};

firebase.initializeApp( config );

export const auth = firebase.auth();
export const db = firebase.firestore();
export const storage = firebase.storage();
export const timeStamp = firebase.firestore.Timestamp;
export type TimeStamp = firebase.firestore.Timestamp;
export const fieldValue = firebase.firestore.FieldValue;
export type FieldValue = firebase.firestore.FieldValue;
export const authPersistenceLocal = firebase.auth.Auth.Persistence.LOCAL;
export const authPersistenceSession = firebase.auth.Auth.Persistence.SESSION;
export const emailAuthProvider = firebase.auth.EmailAuthProvider;
